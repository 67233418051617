<template>
    <v-container>
        <v-row>
            <v-col>

                <UsedUnitsChip :usedUnits="getUsedUnits(membership)"
                    :totalUnits="Number(membership.membership_type.unit)" />

                <v-btn v-show="getUsedUnits(membership) > 0" icon @click="show = !show">
                    <v-icon>{{
                        show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                </v-btn>
            </v-col>

        </v-row>
        <v-row v-for="(item) in membership.planning_memberships" :key="item.id" v-show="show" no-gutters class="mb-1">
            <v-col class="shrink" cols="3">
                <v-chip x-small color="green" text-color="white">
                    <v-icon v-if="item.planning.canceled" small left>
                        mdi-cancel
                    </v-icon>
                    {{ item.unit }}
                </v-chip>
            </v-col>
            <v-col class="grow">
                {{ $helpers.date.getFullDateDisplay(item.planning.dateBegin) }}
                <!-- {{ $helpers.date.getTimeStringFromDate(item.planning.dateBegin) }}
                <v-icon small>mdi-chevron-right</v-icon>
                {{ $helpers.date.getTimeStringFromDate(item.planning.dateEnd) }} -->
            </v-col>
            <v-col class="shrink">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="editPlanning(item.planning)" v-bind="attrs" v-on="on" icon x-small>
                            <v-icon>
                                mdi-eye
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Voir les détails de cette séance</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import UsedUnitsChip from "@/components/Admin/Unit/UsedUnitsChip";

export default {
    props: {
        membership: Object,
    },
    components: {
        UsedUnitsChip,
    },
    data() {
        return {
            show: false,
        };
    },
    methods: {
        getUsedUnits(membership) {
            return membership.planning_memberships.reduce(function (a, b) {
                return Number(a) + Number(b.unit ?? 0);
            }, 0);
        },
        editPlanning(planning) {
            this.$emit("edit", planning);
        },
    }
}
</script>